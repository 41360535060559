/*************************************************
 * 便利な関数など
 ************************************************/

/**
 * ブレークポイント
 */
const breakPoints = 767;

/**
 * 画面幅がブレークポイントより大きいかどうか
 * @type {Boolean}
 */
var isWide = true;

/**
 * ブレークポイントより大きいかどうかの比較後、
 * 変数'isWide'に値を代入する
 */
function set_isWide() {
  isWide = get_window_width() > breakPoints ? true : false;
}
set_isWide();
$(window).on('resize', $.throttle(1000 / 500, function () {
  set_isWide();
}));

/**
 * ウィンドウの幅を取得する
 */
function get_window_width($test) {
  return window.innerWidth || $(window).width();
}

/**
 * スクロールバーの幅を取得する
 */
function get_scrollbar_width() {
  return window.innerWidth - $(window).width();
}
